import React from 'react'

import { Box, Button } from '@mui/material'
import CustomModal from 'components/modal/customModal'
import Text from 'components/ui/Text'

interface Props {
  isOpened?: boolean
  onAction?: () => void
  onClose?: () => void
}

function UpstakeAdvisoryModal({ isOpened, onAction, onClose }: Props) {
  if (!isOpened) return null

  return (
    <CustomModal
      handleClose={onClose}
      open
      sx={{
        maxWidth: '470px',
        width: '90%',
        maxHeight: '90%',
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={4}
        sx={{ mb: 5 }}
      >
        <Text center title3>
          Upstake will start a new stake period and will prolong your current
          one.
        </Text>

        <Text title3>Would you like to proceed?</Text>
      </Box>

      <Box alignItems="center" display="flex" flexWrap="nowrap" gap={2}>
        <Button
          color="primary"
          size="large"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={onAction}
        >
          Confirm
        </Button>
      </Box>
    </CustomModal>
  )
}

export default UpstakeAdvisoryModal
